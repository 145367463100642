<template>
  <div
    :class="[
      'flex flex-col text-gray-700',
      size === 'sm' ? 'gap-y-1' : 'gap-y-1.5'
    ]"
  >
    <label
      v-if="label"
      :for="id"
      :class="[
        'flex items-center gap-x-1 font-medium',
        size === 'sm' ? 'text-xs' : 'text-sm'
      ]"
    >
      {{ label }} <span v-if="required" class="ml-0.5 text-red-500">*</span>
      <slot name="tooltip">
        <Tippy v-if="tooltip">
          <Icon name="question-mark-circle" class="h-4 w-4" />
        </Tippy>
      </slot>
    </label>
    <div
      :class="[
        'flex w-full items-center border text-gray-700 shadow-sm ',
        !!error
          ? 'border-red-400'
          : isFocused
          ? 'border-cyan-700'
          : 'border-gray-200 hover:border-gray-400',
        size === 'sm' ? 'h-8 rounded-md' : 'h-10 rounded-lg'
      ]"
    >
      <slot name="prepend" :="{ isFocused }"></slot>
      <input
        :id="id"
        ref="input"
        :="$attrs"
        :required="required"
        :value="modelValue"
        :disabled="disabled"
        :class="[
          'h-full w-full bg-transparent focus:border-none focus:outline-none',
          disabled ? 'cursor-not-allowed opacity-70' : '',
          size === 'sm' ? 'p-1.5 text-xs' : 'p-2 text-sm'
        ]"
        @input="onInput"
        @invalid.prevent="onInvalid"
        @change="onInvalid"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
      <slot name="append" :="{ isFocused }"></slot>
    </div>
    <InputHelper v-if="!hideErrorMessage" :text="error" isError />
    <slot :="{ isFocused }"></slot>
  </div>
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'

// defines
const props = defineProps<{
  id: string
  label?: string
  modelValue: unknown
  required?: boolean
  disabled?: boolean
  hideErrorMessage?: boolean
  debounce?: number
  size?: 'sm' | 'md'
  tooltip?: string
}>()
const emit = defineEmits(['update:modelValue'])

// data
const error = ref()
const input = ref<HTMLElement>()
const isFocused = ref(false)

// methods
const onInvalid = (e: Event) => (error.value = validateInput(e))

const onInput = useDebounceFn((event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
}, props.debounce ?? 0)
</script>
